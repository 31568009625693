import * as React from 'react';
import { Helmet } from 'react-helmet';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { main, container, link, profileImage, info } from './Index.module.scss';
import img from '../../images/profile.jpg';

const IndexPage = () => (
  <main className={main}>
    <Helmet>
      <title>Home - Omar Muscatello</title>
      <meta name="description" content="Homepage of omarmuscatello.com" />
      <html lang="en" />
    </Helmet>

    <div className={container}>
      <img className={profileImage} src={img} width={150} height={150} alt="Omar Muscatello face" />
      <div className={info}>
        <h1>Hey!</h1>
        <span>
          This is Omar and that&apos;s my face.
          <br />
          I&apos;m an Engineering Manager at{' '}
          <OutboundLink className={link} href="https://zupit.it" rel="noreferrer nofollow" target="_blank">
            Zupit
          </OutboundLink>
          .
        </span>
      </div>
    </div>
  </main>
);

export default IndexPage;
